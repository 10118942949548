class LoadMore extends HTMLElement {
  constructor() {
    super();
    this._nextPage = 2;
    this._currentPage = 1;
    this._isLoading = false;
    this._savedPosition = localStorage.getItem('scrollPosition');
    this._pageCount = parseInt(localStorage.getItem('pageCount'));
    this._savedCollection = localStorage.getItem('savedCollection');
    this._abortController = null;
    this._scrollDebounceTimer = null;
    this._observer = null;
    this._preloadedProducts = [];
    this._collectionCount = null;
  }

  connectedCallback() {
    // Initialize DOM elements after connection
    const sectionId = this.getAttribute('data-section-id');
    this._button = this.querySelector('button');
    this._productGrid = document.querySelector(`#product-list-${sectionId}`);
    this._collectionTemplate = document.querySelector('main');
    this._productCount = this.querySelector('#product-count');

    if (!this._productGrid) {
      console.error('Product grid not found');
      return;
    }

    this._perPage = parseInt(this._productGrid.getAttribute('per-page'));
    this._collectionTemplate.classList.add('transition-opacity', 'opacity-0');

    this._productCount.innerHTML = this._productGrid.querySelectorAll('.grid__item').length;
    this._setupIntersectionObserver();
    this._setupScrollHandler();

    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this._saveScrollPosition();
      }
    });

    if (this._savedPosition && this._pageCount && this._savedCollection === window.location.pathname) {
      this._restoreState();
    } else {
      this._collectionTemplate.classList.remove('opacity-0');
    }

    this._productGrid.addEventListener('click', this._saveScrollPosition.bind(this));
    this._button.addEventListener('click', () => this.expandShowMore());
  }

  disconnectedCallback() {
    this._observer?.disconnect();
    window.removeEventListener('visibilitychange', this._handleVisibilityChange);
    this._abortController?.abort();
    this._productGrid?.removeEventListener('click', this._saveScrollPosition.bind(this));
    this._button?.removeEventListener('click', () => this.expandShowMore());
  }

  _setupIntersectionObserver() {
    this._observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !this._isLoading) {
          this._preloadProducts();
        }
      },
      { rootMargin: '100px' }
    );

    if (this._button) {
      this._observer.observe(this._button);
    }
  }

  _setupScrollHandler() {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        this._saveScrollPosition();
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);
    this._handleVisibilityChange = handleVisibilityChange;
  }

  _saveScrollPosition() {
    if (this._scrollDebounceTimer) {
      clearTimeout(this._scrollDebounceTimer);
    }
    this._scrollDebounceTimer = setTimeout(() => {
      localStorage.setItem('scrollPosition', window.scrollY.toString());
      const page = this._productGrid.childElementCount;
      localStorage.setItem('pageCount', Math.ceil(page / this._perPage).toString());
      localStorage.setItem('savedCollection', window.location.pathname);
    }, 200);
  }

  async _restoreState() {
    if (this._pageCount <= 1) {
      this._collectionTemplate.classList.remove('opacity-0');
      return;
    }

    // Show loading state
    this._button.innerHTML = 'Loading...';
    this._button.disabled = true;

    try {
      // Create parallel fetch promises for all needed pages
      const fetchPromises = [];
      for (let page = 2; page <= this._pageCount; page++) {
        // Use existing _preloadProducts logic for each fetch
        const searchParams = new URLSearchParams(window.location.search);
        const collectionHandle = this.getAttribute('collection-handle');

        if (!collectionHandle) {
          throw new Error('Collection handle not found');
        }

        const baseUrl = collectionHandle + (collectionHandle.includes('?') ? '&' : '?') + searchParams.toString();
        const paramsObj = { page: page };
        const newURL = this.addParamsToUrl(baseUrl, paramsObj);

        const fetchPromise = fetch(newURL)
          .then((response) => {
            if (!response.ok) throw new Error('Network response was not ok');
            return response.text();
          })
          .then((responseText) => {
            const html = new DOMParser().parseFromString(responseText, 'text/html');
            const sectionId = this.getAttribute('data-section-id');
            const newProductGrid = html.querySelector(`#product-list-${sectionId}`);

            if (!newProductGrid) {
              throw new Error('Product grid not found in fetched content');
            }

            // Update collection count if available
            const countElement = html.querySelector('#collection-count');
            if (countElement) {
              this._collectionCount = parseInt(countElement.getAttribute('count'));
            }

            return Array.from(newProductGrid.children);
          });

        fetchPromises.push(fetchPromise);
      }

      // Wait for all fetches to complete
      const allProducts = await Promise.all(fetchPromises);

      // Add all products to the grid
      const fragment = document.createDocumentFragment();
      allProducts.forEach((productsArray) => {
        productsArray.forEach((product) => fragment.appendChild(product));
      });

      // Batch DOM updates
      requestAnimationFrame(() => {
        this._productGrid.appendChild(fragment);
        this._nextPage = this._pageCount + 1;
        this._currentPage = this._pageCount;

        // Update product count
        const currentCount = this._productGrid.childElementCount;
        this._productCount.innerHTML = currentCount;

        // Update button visibility
        if (currentCount >= this._collectionCount) {
          this._button.classList.add('hidden');
          this._observer?.disconnect();
        }

        // Restore scroll position
        if (this._savedPosition > 0) {
          window.scrollTo(0, parseInt(this._savedPosition));
        }

        this._collectionTemplate.classList.remove('opacity-0');
      });
    } catch (error) {
      console.error('Error restoring state:', error);
      this._collectionTemplate.classList.remove('opacity-0');
    } finally {
      // Reset button state
      this._button.innerHTML = 'Load more';
      this._button.disabled = false;
    }
  }

  addParamsToUrl(url, params) {
    try {
      let urlObj = new URL(url, window.location.origin);
      let searchParams = urlObj.searchParams;
      for (let key in params) {
        searchParams.set(key, params[key]);
      }
      return urlObj.toString();
    } catch (error) {
      console.error('Error constructing URL:', error);
      return url;
    }
  }

  async _preloadProducts() {
    console.log('Preload products called');
    if (this._isLoading) {
      console.log('Already loading, returning');
      return;
    }
    this._isLoading = true;

    if (this._abortController) {
      this._abortController.abort();
    }
    this._abortController = new AbortController();

    try {
      const searchParams = new URLSearchParams(window.location.search);
      const collectionHandle = this.getAttribute('collection-handle');
      console.log('Collection handle:', collectionHandle);

      // Ensure we have a valid collection handle
      if (!collectionHandle) {
        throw new Error('Collection handle not found');
      }

      // Construct the URL properly
      const baseUrl = collectionHandle + (collectionHandle.includes('?') ? '&' : '?') + searchParams.toString();
      const paramsObj = { page: this._nextPage };
      const newURL = this.addParamsToUrl(baseUrl, paramsObj);
      console.log('Fetching URL:', newURL);

      const response = await fetch(newURL, { signal: this._abortController.signal });
      if (!response.ok) throw new Error('Network response was not ok');

      const responseText = await response.text();
      const html = new DOMParser().parseFromString(responseText, 'text/html');

      const sectionId = this.getAttribute('data-section-id');
      const newProductGrid = html.querySelector(`#product-list-${sectionId}`);

      if (!newProductGrid) {
        throw new Error('Product grid not found in fetched content');
      }

      const countElement = html.querySelector('#collection-count');
      if (countElement) {
        this._collectionCount = parseInt(countElement.getAttribute('count'));
      }

      this._preloadedProducts = Array.from(newProductGrid.children);
      this._nextPage++;
      this._currentPage++;

      document.dispatchEvent(new CustomEvent('swym:collections-loaded'));
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error loading more products:', error);
      }
    } finally {
      this._isLoading = false;
    }
  }

  async expandShowMore() {
    const fragment = document.createDocumentFragment();
    this._preloadedProducts.forEach((product) => fragment.appendChild(product));
    this._productGrid.appendChild(fragment);
    this._preloadedProducts = [];

    // Update the displayed product count
    const currentCount = this._productGrid.childElementCount;
    this._productCount.innerHTML = currentCount;

    // Only hide the button if we've actually reached the collection total
    console.log('Current product count:', currentCount);
    console.log('Total collection count:', this._collectionCount);

    if (currentCount >= this._collectionCount) {
      console.log('Hiding load more button - reached total count');
      this._button.classList.add('hidden');
      this._observer?.disconnect();
    } else {
      console.log('More products available, keeping button visible');
      this._button.classList.remove('hidden');
    }
  }
}

customElements.define('load-more', LoadMore);
